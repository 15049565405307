/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './Notification.action';
import * as Sentry from '@sentry/react';

export const initialState = {
    notifications: {}
};

const NotificationReducer = (state = initialState, action) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
        case SHOW_NOTIFICATION: {
            const { msgType, msgText, msgDebug } = action;

            if (msgType === 'error') {
                Sentry.captureException({ errorMsg: msgText, debugText: msgDebug });
            }

            const existingKey = Object.keys(notifications).find(key => {
                const notification = notifications[key];
                return notification.msgType === msgType && notification.msgText === msgText;
            });

            if (existingKey) {
                const existingNotification = notifications[existingKey];
                notifications[existingKey] = {
                    ...existingNotification,
                    count: (existingNotification.count || 1) + 1
                };
            } else {
                notifications[Date.now()] = { msgType, msgText, msgDebug, count: 1 };
            }

            return {
                ...state,
                notifications
            };
        }

        case HIDE_NOTIFICATION: {
            const { [action.id]: removed, ...remainingNotifications } = notifications;
            return {
                ...state,
                notifications: remainingNotifications
            };
        }

        default:
            return state;
    }
};

export default NotificationReducer;
