import './Notification.style';

import SourceNotification from 'SourceComponent/Notification/Notification.component';

export class Notification extends SourceNotification {
    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType, count } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                <p block="Notification" elem="Text">{ msgText } { count > 1 && ` (${count})`}</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
