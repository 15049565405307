import {executePost} from 'Util/Request/Request';
import {getStore} from 'Store';
import {deleteAuthorizationToken} from 'Util/Auth';

const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
    );

function isAuthError(error) {
    const sessionKeywords = ['session', 'sesiunea', 'sessione'];

    const containsSessionKeyword = (text) => {
        if (!text) return false;
        return sessionKeywords.some(keyword => text.toLowerCase().includes(keyword));
    };

    const checkError = (err) => {
        if (err?.extensions?.category === 'graphql-authorization') {
            return containsSessionKeyword(err?.debugMessage) || containsSessionKeyword(err?.message);
        }
        return false;
    };

    if (Array.isArray(error)) {
        return error.some(checkError);
    }
    return checkError(error);
}

export function executePostWithAuthCheck(queryObject) {
    return executePost(queryObject).catch((error) => {
        const {dispatch} = getStore();
        if (isAuthError(error)) {
            deleteAuthorizationToken();

            MyAccountDispatcher.then(({default: dispatcher}) => dispatcher.logoutOnSessionError(dispatch));
        } else {
            throw error;
        }
    });
}
