import { Field } from 'Util/Query';

export class PromotionsQuery {
    getPromoItemsForPopupQuery() {
        return new Field('promoItemsForPopup')
            .addFieldList([
                'enable',
                'title',
                'title_open',
                'button_url',
                new Field('items').addFieldList([
                    'sku',
                    'name',
                    'image_url',
                    'price',
                    'product_url',
                    'top_product',
                    'type',
                    'custom_url'
                ])
            ]);
    }

    getQuery() {
        return new Field('promotions')
            .addFieldList(this._getPromotionsFields());
    }

    _getPromotionsFields() {
        return [
            'id',
            'slug',
            'banner_url',
            'enabled_in_promotion_page',
            this._getPromotionsFeaturedField()
        ];
    }

    _getPromotionsFeaturedField() {
        return new Field('featured')
            .addFieldList(this._getPromotionsFeaturedFields());
    }

    _getPromotionsFeaturedFields() {
        return [
            'title',
            'subtitle',
            'button_text',
            'background_color',
            'background_image',
            'background_image_mobile_featured',
            'show_text_featured',
            'text_color_list'
        ];
    }
}

export default new PromotionsQuery();
